<template>
  <v-container class="flex-container">
    <v-row no-gutters>
      <v-col class="d-flex justify-center align-center mb-1 mt-2">         
        <v-btn rounded  @click="startTimer(); scrollTop();"  dark v-if="!timerRunning" color="green" x-large class="px-6 rounded-lg">
          <v-icon large left>mdi-play</v-icon> Iniciar
        </v-btn>
        <v-btn rounded   @click="stopTimer"  dark v-if="timerRunning" color="error" x-large class="px-6 rounded-lg">
          <v-icon large left>mdi-stop</v-icon> Detener
        </v-btn>

        <v-btn rounded    fab @click="mute=!mute" dark  class="ml-4" :color="mute?'':'primary darken-1'">
          <v-icon  v-if="mute" large>
            mdi-volume-off
          </v-icon>
          <v-icon  v-else large>
            mdi-volume-high
          </v-icon>
        </v-btn>


        <!-- <v-btn rounded @click="pauseTimer" text dark v-if="false" color="warning" x-large>
          <v-icon large>mdi-pause</v-icon> Pausar
        </v-btn> -->
        <!--   <v-btn @click="dialogConfig=true" text color="primary" v-if="!timerRunning && !isSafari">
              <v-icon left>mdi-cog-outline</v-icon>
              config
            </v-btn> -->
       <!--  <v-btn rounded @click="resumeTimer" text dark v-if="timerPaused" color="primary" x-large>
          <v-icon large>mdi-play</v-icon> Reanudar
        </v-btn> -->
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col class="d-flex justify-center mt-3">
        <v-progress-circular :rotate="90" :size="260" :width="9" :value="progress" :total="setDurationInSeconds"
          :color="returnProgressColor">
          <p class="text-h3">
            {{ timeRemaining && timeRemaining > -1  ? formatTime(timeRemaining) : '0s' }}
          </p>
        </v-progress-circular>
      </v-col>
    </v-row>
    <v-row no-gutters class="mt-4 pa-4" v-if="!timerRunning && !timerPaused">
       <v-col cols="12">
          <v-select class="mb-2 px-3" outlined dense :items="cronoTypes" v-model="cronoType" @change="scrollTop" label="Tipo de Cronómetro" hide-details>
             <template v-slot:item="{ item }">
              <v-card color="#00000000" class="pa-0" elevation="0">
                <v-card-title>
                  <span>{{ item.text }}</span>
                </v-card-title>
                <v-card-text>{{ item.description }}</v-card-text>
                <v-card-text v-if="item.description2">
                  {{ item.description2 }}
                </v-card-text>
                <v-card-text v-if="item.description3">
                  {{ item.description3 }}
                </v-card-text>
              </v-card>
            </template>
          </v-select>
        </v-col>
      <v-col cols="4" class="pa-2">
        <v-row no-gutters>
          <v-col cols="8">
            <h4 style="font-weight:bold;text-align: center;" >
            # SETS
          </h4>
          <input disabled  style="border: 0px;
    width: 100%;
    height: 60px;
    border-radius: 5px;
    text-align: center;" type="number" min="1"  id="sets" label="#Sets" v-model="sets"/>
          </v-col>
          <v-col cols="4" class="">
            <v-btn icon block large  color="primary" class="mt-2" @click.stop="sets++" style=" border: 1px solid var(--v-primary-base);"
            @mousedown="startIncrements" @mouseup="stopIncrements" @mouseleave="stopIncrements"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
            <v-btn icon block large color="primary" class="mt-1" @click.stop="sets>1?sets--:''" style=" border: 1px solid var(--v-primary-base);"
            @mousedown="startDecrements" @mouseup="stopDecrements" @mouseleave="stopDecrements"
              >
              <v-icon>mdi-minus</v-icon>
              </v-btn>
          </v-col>
        </v-row>
      </v-col>
       <v-col :cols="cronoType=='workrest'?4:8" class="pa-2"  v-bind:class="cronoType == 'workrest' ? 'workrest' : 'emom'">
        <h4 style="font-weight:bold;text-align: center;">
          Duración
        </h4>
        
        <vue-timepicker id="setDuration"  style="font-size:20px;" minute-label="Minutos" second-label="Segundos"
              label="Duracion de set" drop-direction="up"
              v-model="setDuration" format="mm:ss" @change="scrollTop"
              @input="changeDuration"
        ></vue-timepicker>
        </v-col>
        <v-col cols="4" class="pa-2 restduration" v-if="cronoType=='workrest'"  v-bind:class="cronoType == 'workrest' ? 'workrest' : 'emom'">
          <h4 style="font-weight:bold;text-align: center;">
            Descanso
          </h4>
          <vue-timepicker id="restDuration" @change="scrollTop"  drop-direction="up" style="font-size:20px;"  minute-label="Minutos" second-label="Segundos"
                label="Descanso"
                v-model="restDuration" format="mm:ss"
        
          ></vue-timepicker>        </v-col>
    </v-row>
    <v-row no-gutters v-if="timerRunning || timerPaused">
      <v-col class="text-center">

        <div class="text-h5 mt-5"> 
          
          <template v-if="remainingSets-1>0" >

         
          <v-alert color="success" x-large  text>
  Completados #{{ currentSet -1 }}
        </v-alert>
        
        <v-alert color="info" x-large text>
    Faltan #{{ remainingSets  }}
          </v-alert>

           </template>
            <template v-else>
              <v-alert color="success" x-large text>
                 ULTIMO SET!!
                </v-alert>
            </template>
        
        
        </div>
      </v-col>
    </v-row>
    <v-dialog v-model="dialogConfig">
      <v-card>
        <v-card-title>
          <span class="headline">Configuración</span>
        </v-card-title>
        <v-card-text class="text-center">
          <v-subheader>
            <v-icon left>
              mdi-metronome
            </v-icon>
            Sonido de metronomo</v-subheader>
           <v-btn-toggle v-model="$store.state.cronometerSounds.metronome.selected">
         <!--  <v-btn @click="changeMetronomeSound(0)" :disabled="disabled">
            <v-icon left>
                    mdi-volume-high
                  </v-icon>
           SONIDO 1
          </v-btn> -->
          <v-btn @click="changeMetronomeSound(1)" :disabled="disabled"> 
            <v-icon left>
                    mdi-volume-high
                  </v-icon>
           sonido 2
          </v-btn>
        </v-btn-toggle>
        <v-subheader>
          <v-icon left>
            mdi-alarm
          </v-icon>
          Sonido de alerta</v-subheader>
             <v-btn-toggle v-model="$store.state.cronometerSounds.alert.selected" >
            <v-btn @click="changeAlertSound(0)" :disabled="disabled">
              <v-icon left>
                    mdi-volume-high
                </v-icon>
              sonido 1
            </v-btn>
            <v-btn @click="changeAlertSound(1)" :disabled="disabled">
              <v-icon left>
                    mdi-volume-high
                  </v-icon>
               sonido 2
            </v-btn>
          </v-btn-toggle>
          <v-subheader>
            <v-icon left>
              mdi-flag-checkered
            </v-icon>
            Sonido de Finalización</v-subheader>
               <v-btn-toggle v-model="$store.state.cronometerSounds.finish.selected">
              <v-btn @click="changeFinishSound(0)" :disabled="disabled">
                <v-icon left>
                  mdi-volume-high
                </v-icon>
               sonido 1
              </v-btn>
              <v-btn @click="changeFinishSound(1)" :disabled="disabled">
                <v-icon left>
                    mdi-volume-high
                  </v-icon>
                 sonido 2
              </v-btn>
            </v-btn-toggle>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text  @click="dialogConfig = false">Volver</v-btn>  
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { set } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import { Howl } from 'howler';
// Main JS (in UMD format)
import VueTimepicker from 'vue2-timepicker'

// CSS
import 'vue2-timepicker/dist/VueTimepicker.css'

import { Capacitor } from '@capacitor/core';
import { NativeAudio } from '@capacitor-community/native-audio'
import soundFile from '@/assets/sounds/metronome1.mp3';


const isCapacitor = Capacitor.isNativePlatform();

const initialData = {
      prepTime: 5,  // preparation time in seconds
      isPreparation: false,
      timer:null,
      metronomeSoundSelected: 1,
      alertSoundSelected: 0,
      endSoundSelected: 0,
      disabled: false,
      dialogConfig: false,
      cronoType: 'interval',
      cronoTypes: [
         {
          text: "Trabajo con descanso",
          value: "workrest",
          description: "Ejemplo Tabata 20 segundos de trabajo 10 segundos de descanso 8 rondas" 
        },
        {
          text: "Intervalo",
          value: "interval",
          description: "AMRAP ejemplo: 10 minutos de 10 burpees o mas ejercicios sin parar por 1 set",
          description2: "EMOM ejemplo: 2 minutos de 30 burpees o mas ejercicios y descansas el resto del tiempo ",
        }
      ],
      sets: 2,
      setDuration: "00:30",
      restDuration: "00:20",
      timeRemaining: 0,
      setType: 'workout',
      intervalId: null,
      timerRunning: false,
      setsCompleted: 0,
      savedState: null,
      timerPaused: false,
      context: null,
      mute: false,
      
}

export default {
  //watch changes in the props and update the data
  watch: {
    setSecondsDuration: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          this.setDuration = this.formatTime(newVal);
        }
      }
    },
    restSecondsDuration: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          this.restDuration = this.formatTime(newVal);
        }
      }
    },
    type: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          this.cronoType = newVal;
        }
      }
    }
  },



  props: {
    type: {
      type: String,
      default: null
    },
    setSecondsDuration: {
      type: Number,
      default: null
    },
    restSecondsDuration: {
      type: Number,
      default: null
    },
    sets: {
      type: Number,
      default: null
    }
  },
  beforeDestroy() {
    if (this.timerRunning) {
      clearInterval(this.intervalId);
    }
  },
  name: 'ChronoApp',
  components: {
    VueTimepicker
  },
  data() {
    return {
      ...initialData,
      isCapacitor
    };
  },
  async mounted() { 

    this.$vuetify.goTo(0);

    try {
      if (!this.isCapacitor) {
       await NativeAudio.preload({
          assetId: 'metronome',
          assetPath: require('@/assets/sounds/metronome1.mp3'),
          isUrl: true
        });

        await NativeAudio.preload({
            assetId: 'alert',
            assetPath: require('@/assets/sounds/alert0.mp3'),
            isUrl: true
        });

        await NativeAudio.preload({
            assetId: 'finish',
            assetPath: require('@/assets/sounds/finish0.mp3'),
            isUrl: true
        });
      } else {
       await NativeAudio.preload({
          assetId: 'metronome',
         assetPath: 'public/sounds/metronome1.mp3',
       });

       await NativeAudio.preload({
          assetId: 'alert',
         assetPath: 'public/sounds/alert0.mp3',
       });

        await NativeAudio.preload({
            assetId: 'finish',
          assetPath: 'public/sounds/finish0.mp3',
        });

      }
    } catch (error) {
      console.log(error);
    }


    if(this.$props.type){
      this.cronoType = this.$props.type

      if(this.$props.type == 'workrest'){
        this.setDuration = this.formatTimeInSecondsToHHMM(this.$props.setSecondsDuration);
        this.restDuration = this.formatTimeInSecondsToHHMM(this.$props.restSecondsDuration);
      }

      if(this.$props.type == 'interval'){
        this.setDuration = this.formatTimeInSecondsToHHMM(this.$props.setSecondsDuration);
      }

      this.sets = this.$props.sets

    }

   


  },
  computed: {
    isSafari() {
      return /Safari/.test(navigator.userAgent) && !/Chrome/.test(navigator.userAgent) && !window.MSStream;
    },
    
    isChrome() {
      return navigator.userAgent.indexOf('Chrome') != -1;
    },
    isIOS() {
      return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    },
    currentSet() {
      return this.setsCompleted + 1;
    },
    setDurationInSeconds() {
      const [minutes, seconds] = this.setDuration.split(':').map(Number);

      if (minutes > 0) {
        return (minutes * 60) + seconds;
      } else {
        return seconds;
      }
    },
    restDurationInSeconds() {
      const [ minutes, seconds] = this.restDuration.split(':').map(Number);

      if (minutes > 0) {
        return (minutes * 60) + seconds;
      } else {
        return seconds;
      }
    },
    progress() {

      if(this.prepTime > 0 && this.isPreparation)
        return 100 - ((this.timeRemaining / this.prepTime) * 100);
      


      const totalDuration = this.setType === 'workout'
        ? this.setDurationInSeconds
        : this.restDurationInSeconds;
      const progress = (this.timeRemaining / totalDuration) * 100;
      return Math.max(0, Math.min(100, progress));
    },
    remainingSets() {
      if (this.setsCompleted >= this.sets) {
        return -1;
      } else {
        return this.sets - this.setsCompleted;
      }
    },
    returnProgressColor() {
      if (!this.timerRunning) {
        return 'success';
      }

    
      if (this.isPreparation) {
        return 'red darken-3'; 
      }



      if (this.setType === 'workout') {
        return 'yellow darken-3';
      } else {
        return 'primary';
      }

    },
  },
  methods: {
    formatTimeInSecondsToHHMM(secondsInt){
      //this should return a HH:MM string always not 6:00 and neither 6:0 and neither 06:8
      let minutes = Math.floor(secondsInt / 60);
      let timeseconds = secondsInt - minutes * 60;
      return `${String(minutes).padStart(2, '0')}:${String(timeseconds).padStart(2, '0')}`;



    },
    scrollTop() {
      this.$vuetify.goTo(0);
    },
    playMetronome() {
      if(this.mute){
        return
      }
        NativeAudio.play({
        assetId: 'metronome',
        volume: 1,
      });
    },
    playAlert() {

      if(this.mute){
        return
      }

      NativeAudio.play({
        assetId: 'alert',
        volume: 1,
      });
    },
    playFinish() {
      if(this.mute){
        return
      }
      NativeAudio.play({
        assetId: 'finish',
        volume: 1,
      });
    },
    startIncrements() {
      this.timer = setInterval(() => {
        this.sets++
      }, 300); // adjust the delay as needed
    },
    stopIncrements() {
      clearInterval(this.timer);
    },
    startDecrements() {
      this.timer = setInterval(() => {
        this.sets--
      }, 300); // adjust the delay as needed
    },
    stopDecrements() {
      clearInterval(this.timer);
    },
     resetTimer() {
      clearInterval(this.intervalId);
      this.timerRunning = false;
      this.timerPaused = false;

      Object.assign(this.$data, initialData);
    },
    pauseTimer() {
      if (this.intervalId) {
        clearInterval(this.intervalId);
        this.savedState = {
          setsCompleted: this.setsCompleted,
          timeRemaining: this.timeRemaining,
          setType: this.setType,
          timerRunning: false,
        };
        this.timerRunning = false;
        this.timerPaused = true;
      }
    },
    changeDuration() {
      this.timeRemaining = this.setDurationInSeconds;
    },
     startTimer() {
      if (!this.timerRunning && !this.isPreparation) { // Check if the timer is not running
        this.isPreparation = true;
        this.timeRemaining = this.prepTime;
      } else if (this.setType === 'workout' || this.isPreparation) {
        this.isPreparation = false;  // reset preparation flag
        this.timeRemaining = this.setDurationInSeconds;
      } else {
        this.isPreparation = false;  // reset preparation flag
        this.timeRemaining = this.restDurationInSeconds;
      }

      this.intervalId = setInterval(this.countdown, 1000);
      this.timerRunning = true;
    }

,
    stopTimer() {
      clearInterval(this.intervalId);
      this.timerRunning = false;
      this.timerPaused = false;
      this.savedState = null;
      this.setsCompleted = 0;
      this.timeRemaining = 0;
      this.setType = 'workout';
      this.intervalId = null;
      
    },
    resumeTimer() {
      // check if there is a saved state
      if (this.savedState) {
        // restore state to the data properties
        this.setsCompleted = this.savedState.setsCompleted;
        this.timeRemaining = this.savedState.timeRemaining;
        this.setType = this.savedState.setType;
        this.timerRunning = true;
        this.timerPaused = false;
        // clear saved state
        this.savedState = null;
        // start the timer
        this.intervalId = setInterval(this.countdown, 1000);
      }
    },
    formatTime(timeInSeconds) {
      const hours =0
      const minutes = Math.floor((timeInSeconds % 3600) / 60);
      const seconds = Math.floor(timeInSeconds % 60);
      const formattedMinutes = String(minutes).padStart(2, '0');
      const formattedSeconds = String(seconds).padStart(2, '0');
      if (hours > 0) {
        const formattedHours = String(hours).padStart(2, '0');
        return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
      } 
      else if (minutes > 0) {
        return `${formattedMinutes}:${formattedSeconds}`;
      } else {
        return `${formattedSeconds}s`;
      }
    },
    countdown() {
      if (this.$route.name != 'Chrono') {
        this.stopTimer();
        return
      }

      
          


        this.timeRemaining--;
        if (this.isPreparation) {
          if (this.timeRemaining <= 0) {
              this.playAlert()

            clearInterval(this.intervalId);
            this.timerRunning = false; // Reset the timerRunning state
            this.startTimer();
            return;  // exit out early so the rest of the logic doesn't execute
          }
        } else {
          if (this.timeRemaining >= 4) {
              this.playMetronome()
          }
          else if (this.timeRemaining < 4 && this.timeRemaining > 0) {
              this.playAlert()
          }
          else if (this.timeRemaining <= 0) {
            clearInterval(this.intervalId);
            this.setType = this.setType === 'workout' ? 'rest' : 'workout';

            if (this.cronoType === 'interval') {
              this.setType = 'workout';
            }

            if (this.setType === 'rest') {
              this.startTimer();
            }
            else {
              this.setsCompleted++;
              this.startTimer();
            }

            if (parseInt(this.sets) === this.setsCompleted) {
              this.playFinish()
              
              this.$notify({
                group: 'feedback',
                duration: 5000,
                type: 'success',
                title: 'Felicitaciones!',
                text: 'Completaste todos los sets.',
              });
              this.$emit('sets-completed');

              let previousDuration = this.setDuration
              let previousRestDuration = this.restDuration
              let previousSets = this.sets
              let cronoType = this.cronoType
              this.resetTimer();
              this.cronoType = cronoType
              this.setDuration = previousDuration
              this.restDuration = previousRestDuration
              this.sets = previousSets
            }
          }
      }
     // }); 
       
      

      
    },
    ...mapActions(['updateCronometerSound']),
    /* async changeAlertSound(soundNumber, playSound = true) {
      this.disabled = true;
      const soundPath = require(`@/assets/sounds/alert${soundNumber}.mp3`);
      this.alertSound = new Audio(soundPath);

      
        this.alertSound.play().then(() => {
          setTimeout(() => {
      
            this.disabled = false;
                  this.updateCronometerSound({ type: 'alert', number: soundNumber });

          }, 980);
        }).catch((error) => {
          // Automatic playback failed.
          // Show a UI element to let the user manually start playback.
        });
      

     

      
    }, */
     changeAlertSound(soundNumber, playSound = true) {
      const soundPath = require(`@/assets/sounds/alert${soundNumber}.mp3`);
      this.alertSound = new Howl({
        src: [soundPath]
      });

      if (playSound) {
        this.disabled = true;
        this.alertSound.play();
        this.updateCronometerSound({ type: 'alert', number: soundNumber });
        this.disabled = false;
      }
    },
    changeMetronomeSound(soundNumber, playSound = true) {
      const soundPath = require(`@/assets/sounds/metronome${soundNumber}.mp3`);
      this.metronomeSound = new Howl({
        src: [soundPath],
        volume: 0.9,
        
      });

      if (playSound) {
         this.disabled = true;
         this.metronomeSound.play();
         this.disabled = false;
         this.updateCronometerSound({ type: 'metronome', number: soundNumber });
      }
    },
    changeFinishSound(soundNumber, playSound = true) {
      const soundPath = require(`@/assets/sounds/finish${soundNumber}.mp3`);
      this.finishSound = new Howl({
        src: [soundPath]
      });

      if (playSound) {
        this.disabled = true;
        this.finishSound.play();
        this.disabled = false;
        this.updateCronometerSound({ type: 'finish', number: soundNumber });
      }
    },
    

    /*
    async changeMetronomeSound(soundNumber, playSound = true) {
      const soundPath = require(`@/assets/sounds/metronome${soundNumber}.mp3`);
      this.metronomeSound = new Audio(soundPath);

      if (playSound) {
        this.metronomeSound.play().then(() => {
            this.disabled = true;
                  setTimeout(() => {

            this.metronomeSound.pause();
                    this.metronomeSound.currentTime = 0;
                    this.disabled = false;
          }, 980);
         }).catch(error => {
           console.log(error);
          });
      }
      this.updateCronometerSound({ type: 'metronome', number: soundNumber });

      

    },
    async changeFinishSound(soundNumber, playSound = true) {
      const soundPath = require(`@/assets/sounds/finish${soundNumber}.mp3`);
      this.finishSound = new Audio(soundPath);

      if (playSound) {
        this.finishSound.play().then(() => {
          this.disabled = true;
                  setTimeout(() => {

            this.finishSound.pause();
                    this.finishSound.currentTime = 0;
                    this.disabled = false;
          }, 980);
         }).catch(error => {
           console.log(error);
          });
        
      }
      this.updateCronometerSound({ type: 'finish', number: soundNumber });
     

    } */
    
  },
  onbeforeunload(e) {
    if (this.timerRunning) {
      clearInterval(this.intervalId);
    }
  },
};

</script>

<style>
.vue__time-picker.time-picker{
  width: 100%;
}

.vue__time-picker.time-picker .display-time{
  border: 0px;
  width: 100%;
  height: 60px;
  border: 2px solid #2e2e2e;
  border-radius: 5px;
      text-align: center;

}

.amrap .vue__time-picker.time-picker .dropdown.drop-up{
  left:-80% !important
}

.theme--dark .time-picker input{
  color:#FFF;
}

.theme--dark #sets{
  color:#FFF;
}   

input#sets{
  width: 100%;
  height: 60px;
  text-align: center;
  font-size: 30px;
  font-weight: bold;
}

input#sets::-webkit-inner-spin-button, 
input#sets::-webkit-outer-spin-button {
  opacity: 1;
}

.vue__time-picker .dropdown, .vue__time-picker-dropdown{
  background-color: #1b1b1b;
}

.vue__time-picker .dropdown ul li:not([disabled]).active, .vue__time-picker .dropdown ul li:not([disabled]).active:hover, .vue__time-picker .dropdown ul li:not([disabled]).active:focus, .vue__time-picker-dropdown ul li:not([disabled]).active, .vue__time-picker-dropdown ul li:not([disabled]).active:hover, .vue__time-picker-dropdown ul li:not([disabled]).active:focus{
  background-color: var(--v-primary-base);
}

.vue__time-picker .dropdown ul li, .vue__time-picker-dropdown ul li{
  color: whitesmoke;
}

.flex-container{
    display: flex;
    flex-direction: column;
    height: calc(100vh - 120px);
    justify-content: space-between;
}


.restduration .dropdown{
  left:unset !important;
  right:10px !important;
}

:focus-visible{
  outline: 1px solid var(--v-primary-base);
}
</style>
